<template>
  <v-dialog v-model="open" max-width="640">
    <v-card v-if="order">
      <v-card-title class="headline">
        Delivery Request #{{ order.id.substr(0, 4) }} for:
        {{ order.buyer.name || order.delivery.dropoff_name }}
      </v-card-title>
      <v-card-text>
        <v-icon color="info">phone</v-icon>
        {{ parsedNumber(order.buyer.phone) }}
      </v-card-text>

      <v-row>
        <v-col cols="11" v-if="selected_order_diff.message">
          <v-alert
            outlined
            :type="selected_order_diff.allow ? 'info' : 'error'"
            dense
            class="ml-4"
            >{{ selected_order_diff.message }}</v-alert
          >
        </v-col>
        <v-col cols="11" v-if="order && order.delivery.error">
          Delivery Error:
          <v-alert outlined type="error" dense>
            {{ order.delivery.error }}
          </v-alert>
        </v-col>
      </v-row>

      <v-card-text v-if="selected_order_diff.allow"
        >You are about to submit delivery request.</v-card-text
      >
      <v-card-actions>
        <v-text-field
          v-if="selected_order_diff.ask"
          label="Confirm"
          v-model="confirm_text"
          placeholder="OK"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="
            confirm_text.toLowerCase() !== 'ok' && !selected_order_diff.allow
          "
          @click="setReadyForDelivery"
          >Send Driver</v-btn
        >
        <v-btn @click="open = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  props: ["order"],
  data() {
    return {
      open: false,
      confirm_text: "",
      selected_order_diff: {},
    };
  },
  methods: {
    openDialog() {
      this.selected_order_diff = this.timeToDeliveryInMinutes();
      this.open = true;
    },
    close() {
      this.open = false;
    },
    timeToDeliveryInMinutes() {
      const dTime = moment(this.order.deliveryDateTime).utc();
      const now = moment.utc();

      const diff = dTime.diff(now, "hours");
      if (this.order.orderType != "now" && diff > 1) {
        return {
          allow: false,
          message: `Still ${diff} hours left from delivery time. `,
        };
      }
      if (this.order.orderType != "now" && dTime.diff(now, "minutes") > 30) {
        return {
          allow: true,
          ask: true,
          message: `Still ${dTime.diff(
            now,
            "minutes"
          )} minutes left from delivery time. `,
        };
      }
      if (dTime.diff(now, "days") < 0) {
        return {
          allow: false,
          message: `Too late ${-dTime.diff(
            now,
            "days"
          )} days behind from delivery time. `,
        };
      }
      if (
        dTime.diff(now, "minutes") < -10 &&
        dTime.diff(now, "minutes") > -60 * 24
      ) {
        return {
          ask: true,
          message: `Too late ${-dTime.diff(
            now,
            "minutes"
          )} minutes behind from delivery time. Do you still want to send a driver?`,
        };
      }
      if (dTime.diff(now, "minutes") < -60 * 24) {
        return {
          ask: true,
          message: `Too late ${-dTime.diff(
            now,
            "minutes"
          )} minutes behind from delivery time. `,
        };
      }

      return { allow: true };
    },
    setReadyForDelivery() {
      this.open = false;
      this.$store.dispatch("setDeliveryRequest", {
        id: this.order.id,
        code: "submitting_delivery",
      });
    },
    parsedNumber(number) {
      if (number) {
        const p = parsePhoneNumberFromString(number, "US");
        if (p && p.isValid()) {
          return p.format("NATIONAL");
        }
        return number;
      } else {
        return "Phone number not available";
      }
    },
  },
};
</script>
