var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.orders && _vm.orders.length > 0)?_c('v-container',{attrs:{"fluid":""}},[(_vm.isChef && _vm.profile.settings)?_c('v-row',{attrs:{"align":"center"}},[(!_vm.isAdmin)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-0 ma-0"},[_vm._v("Adjust Lead Time\n            "),_c('v-spacer'),_c('h4',[_vm._v("Need help? (877) 929-9006")])],1),_c('v-expansion-panel-content',[_c('v-slider',{attrs:{"tick-labels":[10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],"max":60,"step":5,"ticks":"always","tick-size":"5"},on:{"change":_vm.changeLeadTime},model:{value:(_vm.defaultLeadTime),callback:function ($$v) {_vm.defaultLeadTime=$$v},expression:"defaultLeadTime"}})],1)],1)],1)],1):_c('v-col',{attrs:{"cols":"12"}},[_vm._v("\n      Reports: "),_c('router-link',{attrs:{"to":"/report/"}},[_vm._v("Reports")])],1)],1):_vm._e(),(_vm.allOrders.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allOrders,"items-per-page":10},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id.substr(0, 4)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.buyer.name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"subtitle-1",class:_vm.getStatusColor(item.status) + '--text',attrs:{"dark":""}},[_vm._v(_vm._s(_vm._f("capitalize")(item.status.replace("_", " "))))])]}},{key:"item.pickupDateTime",fn:function(ref){
var item = ref.item;
return [(item.pickupDateTime)?_c('span',{staticClass:"subtitle-1"},[_vm._v("\n            "+_vm._s(_vm.convertTime(item.pickupDateTime))+"\n          ")]):_c('span',{staticClass:"subtitle-1"},[_vm._v("\n            "+_vm._s(_vm.convertTime(_vm.estPickupDateTime))+"\n            "),_c('small',{staticClass:"caption"},[_vm._v("(Estimated)")])])]}},{key:"item.deliveryMethod",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-2"},[_vm._v("\n            "+_vm._s(_vm._f("capitalize")(item.deliveryMethod))+"\n            ("+_vm._s(item.orderType === "later" ? "Pre-Order" : "Now")+")\n          ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(_vm.isChef)?_c('span',[_vm._v("$"+_vm._s(item.payment.all_fees ? item.payment.all_fees.producer_total : 0))]):_c('span',[_vm._v("$"+_vm._s(item.payment.all_fees.total))])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-sheet',{attrs:{"height":"80","tile":""}},[_c('v-icon',{staticClass:"ma-5",attrs:{"color":"grey lighten-1"},on:{"click":function($event){return _vm.openOrder(item)}}},[_vm._v("info")])],1)]}}],null,false,3708948073)})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1","sm":"3"}},[_c('router-link',{attrs:{"to":"/order/list"}},[_vm._v("Legacy View")])],1)],1),_c('order-ready-dialog',{ref:"orderReadyDialog",attrs:{"order":_vm.selected_order}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"color":"primary","label":"Show Past Orders"},on:{"change":_vm.loadArchiveOrders},model:{value:(_vm.showPastOrders),callback:function ($$v) {_vm.showPastOrders=$$v},expression:"showPastOrders"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.showPastOrders)?_c('order-items',{attrs:{"orders":_vm.archiveOrders,"orderSource":_vm.orderSource,"status":"inactive","isChef":_vm.isChef,"uid":_vm.uid,"mobile":_vm.$vuetify.breakpoint.xs}}):_vm._e()],1)],1)],1):_c('v-container',[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("No active orders")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }