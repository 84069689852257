<template>
  <v-container fluid v-if="orders && orders.length > 0">
    <v-row v-if="isChef && profile.settings" align="center">
      <v-col cols="12" v-if="!isAdmin">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0 ma-0"
              >Adjust Lead Time
              <v-spacer></v-spacer>
              <h4>Need help? (877) 929-9006</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-slider
                @change="changeLeadTime"
                v-model="defaultLeadTime"
                :tick-labels="[10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]"
                :max="60"
                :step="5"
                ticks="always"
                tick-size="5"
              ></v-slider>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" v-else>
        Reports: <router-link to="/report/">Reports</router-link>
      </v-col>
    </v-row>

    <v-row v-if="allOrders.length > 0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="allOrders"
          :items-per-page="10"
          @click:row="handleClick"
        >
          <!-- Orde ID -->
          <template v-slot:item.id=""> </template>
          <template v-slot:item.id="{ item }">
            <span>{{ item.id.substr(0, 4) }}</span>
          </template>

          <!-- Customer Name -->
          <template v-slot:item.name="{ item }">
            <span>{{ item.buyer.name }}</span>
          </template>

          <!-- Customer Number -->
          <!-- <template v-slot:item.phoneNumber="{ item }">
            <span v-if="item.buyer.phone">{{
              parsedNumber(item.buyer.phone)
            }}</span>
            <span v-else class="font-italic">Not Available</span>
          </template> -->

          <!-- ORDER STATUS -->
          <template v-slot:item.status="{ item }">
            <span
              dark
              class="subtitle-1"
              :class="getStatusColor(item.status) + '--text'"
              >{{ item.status.replace("_", " ") | capitalize }}</span
            >
          </template>

          <!-- Order Ready Time -->
          <template v-slot:item.pickupDateTime="{ item }">
            <span v-if="item.pickupDateTime" class="subtitle-1">
              {{ convertTime(item.pickupDateTime) }}
            </span>
            <span v-else class="subtitle-1">
              {{ convertTime(estPickupDateTime) }}
              <small class="caption">(Estimated)</small>
            </span>
          </template>

          <!-- Order Type -->
          <template v-slot:item.deliveryMethod="{ item }">
            <span class="body-2">
              {{ item.deliveryMethod | capitalize }}
              ({{ item.orderType === "later" ? "Pre-Order" : "Now" }})
            </span>
          </template>

          <!-- Order amount -->
          <template v-slot:item.amount="{ item }">
            <span v-if="isChef"
              >${{
                item.payment.all_fees ? item.payment.all_fees.producer_total : 0
              }}</span
            >
            <span v-else>${{ item.payment.all_fees.total }}</span>
          </template>

          <!-- details -->
          <template v-slot:item.details="{ item }">
            <v-sheet height="80" tile>
              <v-icon
                class="ma-5"
                color="grey lighten-1"
                @click="openOrder(item)"
                >info</v-icon
              >
            </v-sheet>
            <!-- <router-link :to="'/profile/' + item.id">{{ item.id }}</router-link> -->
          </template>

          <!-- order actions -->
          <!-- <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-icon color="grey lighten-1" v-on="on">menu</v-icon>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>Order: {{ item.id.substr(0, 4) }}</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(action, index) in actionItems(item)"
                  :key="index"
                  @click="orderAction(item, action.key)"
                >
                  <v-list-item-title>{{ action.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>-->
        </v-data-table>
      </v-col>
    </v-row>
    <v-row align="center">
      <!-- <v-col cols="1" class="offset-sm1">
        <v-btn fab text @click.prevent="refresh">
          <v-icon dark>cached</v-icon>
        </v-btn>
      </v-col> -->

      <v-col cols="1" sm="3">
        <router-link to="/order/list">Legacy View</router-link>
      </v-col>
    </v-row>

    <order-ready-dialog
      ref="orderReadyDialog"
      :order="selected_order"
    ></order-ready-dialog>
    <v-row align="center">
      <v-col cols="6">
        <v-switch
          color="primary"
          v-model="showPastOrders"
          label="Show Past Orders"
          @change="loadArchiveOrders"
        ></v-switch>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12">
        <order-items
          v-if="showPastOrders"
          :orders="archiveOrders"
          :orderSource="orderSource"
          status="inactive"
          :isChef="isChef"
          :uid="uid"
          :mobile="$vuetify.breakpoint.xs"
        />
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-card>
      <v-card-title primary-title>No active orders</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import OrderListItems from "./OrderListItems";
import OrderReadyDialog from "./OrderReadyDialog";
// import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  name: "OrderList",
  components: {
    "order-items": OrderListItems,
    "order-ready-dialog": OrderReadyDialog,
  },
  data() {
    return {
      defaultLeadTime: 0,
      uid: null,
      activeOnly: false,
      observer: null,
      showPastOrders: false,
      selected_order: null,
      headers: [
        { text: "Order ID", value: "id" },
        { text: "Customer", value: "name" },

        { text: "Status", value: "status" },
        { text: "Pickup Time", value: "pickupDateTime" },
        { text: "Delivery Method", value: "deliveryMethod" },

        { text: "Total", value: "amount" },
        {
          text: "View Details",
          value: "details",
          sortable: false,
          align: "center",
        },
        // { text: "Actions", value: "actions", sortable: false, align: "center" }
      ],
      //allOrders: []
    };
  },
  watch: {
    isAdmin() {
      this.loadOrders();
    },
  },
  computed: {
    ...mapGetters({
      activeOrders: "activeOrders",
      archiveOrders: "archiveOrders",
      newOrders: "newOrders",

      profile: "profile",
      isChef: "isChef",
      isAdmin: "isAdmin",
      user: "user",
      orders: "orders",
    }),
    ...mapState(["user"]),

    orderSource() {
      if (this.isChef) {
        return "seller";
      }
      return "buyer";
    },
    allOrders() {
      return this.newOrders.concat(this.activeOrders);
    },
    estPickupDateTime() {
      const defaultLeadTime = this.profile.settings.defaultLeadTime;
      return moment().add(defaultLeadTime, "m");
    },
  },
  methods: {
    loadOrders() {
      if (this.profile && this.profile.settings) {
        this.defaultLeadTime = this.profile.settings.defaultLeadTime - 10;
      }
      if (this.isAdmin) {
        this.headers.unshift({ text: "Seller", value: "seller.name" });

        if (!this.$route.params.uid) {
          this.$store.dispatch("loadSellerOrders", {
            active: !this.showPastOrders,
            recent: true,
          });
          return;
        }
      }
      this.uid =
        this.isAdmin && this.$route.params.uid
          ? this.$route.params.uid
          : this.profile.id;
      let query = { active: true, recent: true, uid: this.uid };
      if (this.uid) {
        query.recent = false;
      }
      this.$store.dispatch("loadOrders", query).then((observer) => {
        this.observer = observer;

        console.log(`All Orders list has size: ${this.allOrders.length}`);
      });
    },
    handleClick(item) {
      this.openOrder(item);
    },
    openOrder(order) {
      this.$router.push(`/order/view/v2.0/${order.sellerUserId}/${order.id}`);
    },
    refresh() {
      this.$store.dispatch("loadOrders").then((observer) => {
        this.observer = observer;
      });
    },
    loadArchiveOrders() {
      console.log("Loading archived orders: ", this.showPastOrders);
      this.loadOrders();
    },

    statusColor(order) {
      let now = moment();
      let orderTime = this.$moment(
        order.deliveryDate + " " + order.deliveryTime,
        "YYYY/MM/DD HH:mm a"
      );

      if (order.status === "new") {
        if (orderTime.diff(now, "minutes") > 60 * 5) {
          return "light-green lighten-4";
        }
        if (orderTime.diff(now, "minutes") > 60 * 2) {
          return "light-blue lighten-4";
        }
        if (orderTime.diff(now, "minutes") >= 60) {
          return "red lighten-5";
        }
        if (orderTime.diff(now, "minutes") >= 30) {
          return "red lighten-3";
        }
        return "red";
      }
      if (order.status === "accepted") {
        if (orderTime.diff(now, "minutes") > 60 * 5) {
          return "light-green lighten-4";
        }
        return "red lighten-3";
      }

      return "#ECEFF1";
    },
    getStatusColor(status) {
      if (status === "new") {
        return "green";
      }
      if (status === "accepted") {
        return "blue";
      }
      if (status === "Failed") {
        return "red";
      }
      return "blue-grey";
    },
    /* parsedNumber(number) {
        const p = parsePhoneNumberFromString(number, "US");
        if (p && p.isValid()) {
          return p.format("NATIONAL");
        }
        return number;
      },*/
    // changes cooking status
    // PARAMS: order id and status (prepping, cooked, and ready)
    updateCookingStatus(order_id, status) {
      this.$store
        .dispatch("setCookingStatus", {
          id: order_id,
          cooking_status: status,
        })
        .then(() => {
          this.$store.dispatch("setMessage", {
            title: "Cooking Status",
            body: "saved",
          });
        });
    },
    showReadyForDelivery(order) {
      return (
        order.deliveryMethod === "delivery" &&
        order.status === "accepted" &&
        this.profile.settings.arrangeThirdPartyDelivery
      );
    },
    onReadyForDelivery(order) {
      this.selected_order = order;
      this.$refs.orderReadyDialog.openDialog();
    },
    actionItems(order) {
      let list = [];

      if (this.showReadyForDelivery(order)) {
        list.push({ title: "Ready for Delivery", key: "ready" });
      }

      if (order.delivery && order.delivery.provier === "postmates") {
        list.push({ title: "Track Postmates", key: "postmates" });
      }

      return list;
    },
    orderAction(order, actionKey) {
      switch (actionKey) {
        case "ready":
          this.onReadyForDelivery(order);
          break;
        case "postmates":
          break;
        default:
          console.log("Order Action Parameter Failed");
      }
    },
    convertTime(utc) {
      if (moment(utc).isSame(moment(), "day")) {
        return moment(utc).format("hh:mm A");
      }
      return `${moment(utc).format("hh:mm A")}  (${moment(utc).fromNow()})`;
    },
    getReadyTime(acceptDate, leadTime) {
      if (leadTime) {
        return moment()
          .utc(acceptDate)
          .add(leadTime, "milliseconds")
          .format("hh:mm a");
      } else {
        let profileLeadTime = this.profile.settings.defaultLeadTime;
        if (profileLeadTime) {
          return moment()
            .utc(acceptDate)
            .add(profileLeadTime, "milliseconds")
            .format("hh:mm a");
        } else {
          return moment().utc(acceptDate).format("hh:mm a");
        }
      }
    },
    changeLeadTime() {
      this.$store.dispatch("saveSettings", {
        defaultLeadTime: parseInt(this.defaultLeadTime) + 10,
      });
    },
  },
  mounted() {
    this.loadOrders();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer();
    }
  },
};
</script>
